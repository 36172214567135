@charset "utf-8";

/* お問い合わせフォーム */
.inquiry {
  fieldset {
    margin: 0;
    padding: 4%;
    border: $color_highlight_bg solid 5px;
    border-radius: 10px;
  }

  .table-default {
    border: none;

    th {
      background: none;
      border: none;
      text-align: left;
      padding: .5em 50px .5em .5em;
      vertical-align: top;
      font-weight: bold;
      position: relative;

      .required {
        @media (min-width: 768px) {
          position: absolute;
          top: .7em;
          right: 0;
        }
      }
    }

    td {
      background: none;
      border: none;
      padding: .5em;
    }
  }

  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  select,
  textarea {
    width: 100%;
    padding: 5px;
    color: #000;
    @include fontsize(16);
    margin: .2em .2em;
  }

  input[type="number"] {
    width: 65px;
    text-align: center;
  }

  input.p-postal-code {
    width: 100px;
  }

  input.p-region {
    width: 100px;
  }

  input.p-locality {
    width: 150px;
  }

  input.p-street-address {
    width: 100%;
  }

  input.p-extended-address {
    width: 100%;
  }

  .mwform-tel-field {
    display: flex;
    justify-content: space-between;
  }

  .submit {
    margin: 1em 0;
    text-align: center;
  }

  .required {
    display: inline-block;
    background: #c00;
    color: white;
    padding: .2em .5em;
    @include fontsize(12);
    margin-left: .5em;
    flex-shrink: 0;
  }

  .form_button {
    @extend .button;
  }
}


@media (min-width: 768px) {
  .inquiry {
    input[type="text"],
    input[type="url"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    input[type="text"],
    select {
      //width: 60%;
      margin: 0 .2em;
    }

    input[type="number"] {
      width: 65px;
    }

    input.p-postal-code {
      width: 100px;
    }

    input.p-region {
      width: 100px;
    }

    input.p-locality {
      width: 150px;
    }

    input.p-street-address {
      width: 100%;
    }

    input.p-extended-address {
      width: 100%;
    }

    textarea {
      width: 100%;
    }

    .input_wrap {
      display: block;
      margin: 0 .2em;

      input, textarea {
        margin: 0;
      }

      &:last-child {
        margin-right: auto;
      }
    }

    .submit {
      margin: 60px 0 0;
    }
  }
}

.form_button_wrap {
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  button {
    margin: .5em !important;
  }
}

.privacy_wrap {
  margin-top: 5%;
  border: #ccc solid 1px;
  padding: 1em;
  height: 400px;
  overflow-y: scroll;
}

.mw_wp_form_confirm .input-show {
  display: none;
}

.mw_wp_form_input .input-hide {
  display: none;
}

