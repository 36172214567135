@charset "utf-8";

html {
  font-size: 62.5%;
  overflow-x: hidden;

  &.scrollPrevent {
    overflow: hidden;
    height: 100%;
  }
}

body {
  background: $color_body_bg;
  font-family: $base_font_family;
  @include body_fontsize($base_font_size);
  line-height: 1.5;
  color: $color_text;
  height: auto !important;
  overflow: hidden;
  @media (max-width: 767px) {
    @include body_fontsize($sp_base_font_size);
  }
}

//$font_base_gothic IEだとテキスト下部に余白が発生するため、IEのみメイリオにする
@if $base_font_family == $font_base_gothic {
  _:-ms-lang(x)::-ms-backdrop, body {
    font-family: Arial, Meiryo, sans-serif;
  }
}

//$font_base_mincho IEだとテキスト下部に余白が発生するため、IEのみMSP明朝にする
@if $base_font_family == $font_base_mincho {
  _:-ms-lang(x)::-ms-backdrop, body {
    font-family: "Times New Roman", "ＭＳ Ｐ明朝", serif;
  }
}

main {
  overflow: hidden;
}

figure, picture {
  margin: 0;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    margin-top: 0.5em;
    text-align: left;
  }
}

a {
  color: $color_link;
  transition: all .3s;

  &:hover {
    color: $color_link_hover;
    text-decoration: none;
  }

  .highlight & {
    color: $color_highlight_link;

    &:hover {
      color: $color_highlight_link_hover;
    }
  }
}


//画像hover時透過
//classの無い a 内の imgは全てhover時に透過となる
//透過したく場合はno-hover等適当なclassを付けて対応
a:not([class]), a.swipebox {
  img {
    transition: all .3s;
  }

  &:hover {
    img {
      opacity: 0.8;
    }
  }
}

img {
  vertical-align: middle;

  &.circle {
    border-radius: 50%;
  }
}

p {
  margin: 0.5em 0 1em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ol,
ul {
  margin: 0 0 1em;
  padding: 0 0 0 2.5em;
  line-height: 1.6;

  &:last-child {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin: 0.5em 0;
    padding-left: 2em;

    &:last-child {
      margin-bottom: 0.5em;
    }
  }
}

ul.list-unstyled {
  padding-left: 0;
  list-style: none;

  ol,
  ul {
    padding-left: 2em;
  }
}

ul.list-inline {
  @include list-horizontal(7px);
  margin-bottom: $base_font_size + px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

main {

  .contents_wrapper {
    max-width: 100%;
    padding: 0;
  }

  section {
    margin-bottom: 100px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }

    &:last-child {
      @media (max-width: 767px) {
        margin-bottom: 80px;
      }
    }
  }
}

@media (min-width: 768px) {
  p {
    margin: 0.5em 0 1em;
  }
}

@media (max-width: 900px) {
  .gutters .span_3.column_sub,
  .gutters .span_9.column_main {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .gutters .span_3.column_sub {
    .sidebar {
      letter-spacing: -.40em;

      .widget_wrap {
        letter-spacing: normal;
        display: inline-block;
        vertical-align: top;
        width: 49%;
        margin-left: 2%;

        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
    }
  }
}

// コンテナの最大幅
@media (min-width: 768px) {
  .container {
    max-width: $container+px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.article_button {
  margin-top: 5%;
}

@media (min-width: 768px) {
  .article_detail,
  .article_list {
    &:not(.reverse) {
      .col {
        &.span_12 {
          + .span_4 {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.sidebar {
  visibility: hidden;

  > .widget_wrap {
    visibility: visible;
  }

  ul {
    @include listclear();
    margin-bottom: 6%;

    a {
      color: $color_text;
      text-decoration: none;
      display: block;
      padding: .5em;
      border-bottom: rgba($color_primary, 0.1) solid 1px;

      &:hover {
        color: $color_primary;
        background: rgba($color_primary, 0.05);
      }
    }
  }

}

.gutters .col.span_12 + .span_12 {
  margin-left: 0;
}

.map_wide {
  #map_canvas,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: none;
  }
}

.slick-track {
  margin: 0 auto;
}

.col {
  dl {
    p {
      line-height: 1.4;
      margin: 0;
    }
  }

  p {
    line-height: 1.8;
  }
}

.container {
  padding: 0 10px;
  @media (min-width: ($container + 20)+px) {
    padding: 0;
  }
}

.highlight {
  padding: 4% 0;
  background: $color_highlight_bg;
  color: $color_highlight_text;

  &.round {
    border-radius: 10px;
  }
}

p, .col {
  &.highlight {
    padding: 4%;
  }
}

//ヘッダブレイクポイント格納用DIV
.js-header_break_point {
  display: none;
  z-index: $header_break_point;
}

//IE非対応後に使用
//:root {
//  --header_break_point: $header_break_point;
//}

// bootstrap風のflexオプション（必要に応じて随時追加予定）
// rowに併記して使用すること
// https://getbootstrap.jp/docs/4.3/utilities/flex/#enable-flex-behaviors

@media (min-width: 768px) {
  .d-flex {
    display: flex;
  }
  .row {
    &.flex-wrap {
      flex-wrap: wrap;
    }

    &.flex-nowrap {
      flex-wrap: nowrap;
    }
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .align-items-start {
    align-items: flex-start;
  }

  .align-items-center {
    align-items: center;
  }

  .align-items-end {
    align-items: flex-end;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }
}

//コンテンツのマージン調整
.column_main {
  .col {
    p, table {
      + *:not(p) {
        margin-top: 40px;
      }
    }
  }
}