@charset "utf-8";

@mixin fontsize($size, $base: 10) {
  font-size: ($size / $base) * 1rem;
}

// for Google Chrome
@mixin body_fontsize($size, $base: 10) {
  font-size: ($size / $base) * 1em;
}

@mixin listclear() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin list-horizontal($padding: 0) {
  @include listclear();
  @if $padding != 0 {
    margin-left: -$padding;
  }
  @include fontsize(0);
  > li {
    display: inline;
    padding-left: $padding;
    padding-right: $padding;
    white-space: nowrap;
    @include fontsize($base_font_size);
  }
}

@mixin image-replace() {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

@mixin v-center() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin position-center-wide() {
  position: absolute;
  left: -300%;
  right: -300%;
  margin: auto;
}

@mixin position-center() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
