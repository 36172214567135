@charset "utf-8";
//*****************共通*****************//
.icon-tel {
  &:before {
    content: '\f879';
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;
  }
}

//*****************TOP*****************//
.top_menu {
  background: $color_primary;
  padding: 60px 0;
  color: white;
  @media (max-width: 767px) {
    padding: 30px 0;
  }

  a {
    display: block;
    background: white;
    text-decoration: none;
    padding: 0 0 2%;
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
    }

    img {
      @media (max-width: 767px) {
        width: 50%;
      }
    }

    h3 {
      margin-top: 1em;
      padding-bottom: 40px;
      @media (max-width: 767px) {
        width: 50%;
      }

      &:after {
        content: none;
      }

      &:before {
        content: '\f138';
        font-family: 'Font Awesome 5 Free';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

.top_pickUp {
  .icon_wrapper {
    width: 130px;
    margin: 0 auto 1em;
    background: $color_primary;
    height: 0;
    padding-top: 130px;
    border-radius: 50%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  h3 {
    padding: 0;

    &:after {
      content: none;
    }
  }
}

//*****************下層*****************//
