@charset "utf-8";
// Q&Aパーツ(Q&A parts)
.qa {
  h3 {
    &.question {
      @include fontsize(16);
      border: none;
    }
  }

  .question {
    position: relative;
    margin-bottom: 1em;
    padding: 1em 50px 1em 1em;
    background-color: $color_qa_bg;
    font-weight: normal;
    user-select: none;

    &:after {
      content: none;
    }

    &:before {
      content: none;
    }

    &.open:after {
      transform: rotate(180deg);
    }
  }

  .answer {
    padding: 0 0 0 1em;

    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  &.qa_active {
    .question {
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 1em;
        left: auto;
        top: 50%;
        bottom: auto;
        margin-top: -12px;
        background: url(/wp-content/uploads/arrow_down.svg) center center no-repeat;
        background-size: 24px;
        transition: .5s;
        border: none;
      }

      &:hover {
        background: darken($color_qa_bg, 3%);
      }
    }

    .answer {
      display: none;
    }
  }
}

.qa.qa-open {
  .question {
    padding-right: 1em;
    cursor: default;

    &:hover {
      background-color: $color_qa_bg;
    }

    &:after {
      display: none;
    }
  }

  .answer {
    display: block;
  }
}

.highlight .qa {
  $color_highlight_qa_bg: lighten($color_qa_bg, 5%);

  .question {
    background: $color_highlight_qa_bg;

    &:hover {
      background: darken($color_highlight_qa_bg, 3%);
    }
  }

  &.qa-open .question {
    &:hover {
      background: $color_highlight_qa_bg;
    }
  }
}
