@charset "utf-8";

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.lead {
  color: $color_primary;
  @include fontsize(20);
}

.text-xxlarge {
  @include fontsize(24);
}

.text-xlarge {
  @include fontsize(20);
}

.text-large {
  @include fontsize(16);
}

.text-small {
  @include fontsize(12);
}

.text-xsmall {
  @include fontsize(10);
}

// 文字色
.text-white {
  @if variable-exists(color_white) {
    color: $color_white !important;
  } @else {
    color: #fff !important;
  }
}

.text-black {
  @if variable-exists(color_black) {
    color: $color_black !important;
  } @else {
    color: #000 !important;
  }
}

.text-red {
  @if variable-exists(color_red) {
    color: $color_red !important;
  } @else {
    color: #f00 !important;
  }
}

.text-primary {
  color: $color_primary !important;
}

@media (max-width: 767px) {
  .sp-hide, .pc-only, .tablet-only {
    display: none !important;
  }
  .sp-text-center {
    text-align: center;
  }
  .sp-text-left {
    text-align: left;
  }
  .sp-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .lead {
    @include fontsize(28);
  }
  .text-xxlarge {
    @include fontsize(36);
  }
  .text-xlarge {
    @include fontsize(28);
  }
  .text-large {
    @include fontsize(20);
  }
  .text-small {
    @include fontsize(12);
  }
  .text-xsmall {
    @include fontsize(10);
  }

  .pc-hide, .sp-only {
    display: none !important;
  }
  .pc-text-center {
    text-align: center;
  }
  .pc-text-left {
    text-align: left;
  }
  .pc-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .tablet-only {
    display: none !important;
  }
}

@media (max-width: ($header_break_point - 1)+px) {
  .sp-hide02, .pc-only02, .tablet-only02 {
    display: none !important;
  }
}

@media (min-width: $header_break_point+px) {
  .pc-hide02, .sp-only02 {
    display: none !important;
  }
}

.arial {
  font-family: 'Arial', arial !important
}

.arial_n {
  font-family: 'Arial Narrow', arial !important
}

//TELリンクカラー
.js-tel {
  a {
    color: currentColor;
    text-decoration: none;
    @media (min-width: 768px) {
      pointer-events: none;
    }
  }
}

//フォントサイズ
//ex)<p class="fs22 sp-fs19">
@for $i from 12 through 40 {
  //通常指定
  .fs#{$i} {
    font-size: #{$i}px;
  }

  //SP用指定
  @media (max-width: 767px) {
    .sp-fs#{$i} {
      font-size: #{$i}px !important;
    }
  }
}

// マージン
.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.m-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@for $i from 1 through 5 {
  .mt-#{$i} {
    margin-top: #{$i}em;
  }
}


// 文字反転
.inverse {
  // a:not(.button),
  h2,
  h3,
  h4,
  p {
    color: $color_text_inverse;
  }

  h2 {
    &:after {
      background: $color_text_inverse;
    }

    small,
    span {
      color: $color_text_inverse;
    }
  }
}

.image_right {
  margin-bottom: 1em;
  @media (min-width: 768px) {
    display: inline-block;
    float: right;
    margin-left: 1em;
  }
}


//トップ・下層出し分け
.dsp-home-only {
  display: none;
}

.dsp-under-only {
  display: block;
}

.home {
  .dsp-home-only {
    display: block;
  }

  .dsp-under-only {
    display: none;
  }
}

//ofi cover
.ofi {
  &.cover {
    img {
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 100%;
      height: 100%;
    }
  }
}